<template>
  <div style="height: inherit">
    <Modal @close="closeAddModal" :show="showAddModal" :title="text.add_mt_title[language]">
      <template slot="body">
        <div class="block block__column block__between" style="height: 100%; padding: 10px">
          <div style="padding: 10px; height: 100%; overflow-y: auto">
            <Input
              required
              style="width: 100%"
              @change="saveAdd('name', $event)"
              :label="text.machine_tool_name[language]"
            />
            <Search
              required
              @change="saveAdd('id_company', $event.value)"
              :label="text.manufacturing[language]"
              :query="{
                method: 'post',
                url: 'getSearchCompaniesExpert',
                findName: 'find',
                label: 'name',
                value: 'id',
              }"
            />
            <Search
              required
              :label="text.machine_tool_method[language]"
              @change="saveAdd('id_machine_tool_method', $event.value)"
              :query="{
                method: 'post',
                url: 'getSearchMachineToolTypes',
                findName: 'find',
                label: 'machine_tool_methods',
                value: 'id',
              }"
            />
            <Input
              @change="saveAdd('x_size', +$event)"
              required
              :min="0"
              type="number"
              style="width: 100%"
              :label="text.size_x[language]"
            />
            <Input
              @change="saveAdd('y_size', +$event)"
              required
              :min="0"
              type="number"
              style="width: 100%"
              :label="text.size_y[language]"
            />
            <Input
              required
              @change="saveAdd('z_size', +$event)"
              type="number"
              :min="0"
              style="width: 100%"
              :label="text.size_z[language]"
            />
          </div>
          <div style="padding: 10px" class="block block__right">
            <Button type="default" @click="closeAddModal" :text="text.cancel[language]" />
            <Button
              type="primary"
              :text="text.save[language]"
              @click="addMachineTool"
              style="margin-left: 10px"
            />
          </div>
        </div>
      </template>
    </Modal>
    <PageConstructor
      :form-name="['serial_number', 'machine_tool_name']"
      @setUpdate="setUpdate($event)"
      :query="query"
      :buttons="buttons"
      :showFilter="true"
      @headerClick="headerClick($event)"
      :page-name="text.page_name[language]"
      :columns="columns"
      :add-title="text.add_mt[language]"
    >
      <template slot="edit" slot-scope="{ row }">
        <Edit :row="row" @update="updateTable" />
      </template>
    </PageConstructor>
  </div>
</template>

<script>
import PageConstructor from "@/components/pageConstructor/PageConstructor.vue";
import text from "@/assets/text/machine_tools.json";
import Modal from "@/components/i5Modal/I5Modal.vue";
import Search from "@/components/search/Search.vue";
import Input from "@/components/input/Input.vue";
import Button from "@/components/button/Button.vue";
// import Select from '@/components/select/Select.vue';
import { query, requestFile } from "@/utilities/axios";
import fileSaver from "file-saver";
// import Add from './components/add/Add.vue';
import Edit from "./components/edit/Edit.vue";

export default {
  name: "MachineTools",
  data() {
    return {
      text,
      fileSaver,
      serialNumber: null,
      idMachineTool: null,
      idShop: null,
      idAccClass: null,
      snError: null,
      mtError: null,
      shError: null,
      acError: null,
      tableUpdate: null,
      showAddModal: false,
      addCompanyName: "",
      addMethod: "",
      shops: [],
      accuracyClasses: [],
      addSavedData: {},
      query: {
        method: "post",
        url: "getMachineToolsV2",
        headers: {},
        data: {},
      },
      columns: [
        {
          name: "name",
          label: text.machine_tool_name,
          options: {
            bold: true,
            sort: true,
          },
          form: {
            type: "search",
          },
          basis: 200,
          width: 200,
        },
        {
          name: "method",
          label: text.machine_tool_method,
          options: {
            sort: true,
          },
          basis: 0,
          width: 400,
        },
        {
          name: "company_name",
          label: text.manufacturing,
          options: {
            sort: true,
          },
          basis: 0,
          width: 400,
        },
        {
          name: "inner_size",
          label: text.inner_size,
          options: {
            sort: true,
          },
          basis: 0,
          width: 400,
        },
      ],
      buttons: [
        {
          type: "download",
        },
        {
          type: "main",
          text: text.add_mt[this.$store.state.index.language],
        },
      ],
    };
  },
  computed: {
    language() {
      return this.$store.state.index.language;
    },
  },
  methods: {
    headerClick(type) {
      if (type === "main") {
        this.openAddModal();
      } else if (type === "download") {
        this.downloadList();
      }
    },
    openAddModal() {
      if (this.shops.length === 0) {
        this.getShops();
        this.getAccuracyClasses();
      }
      this.showAddModal = true;
    },
    closeAddModal() {
      this.showAddModal = false;
    },
    setUpdate(func) {
      this.tableUpdate = func;
    },
    updateTable() {
      this.tableUpdate();
    },
    addMachineTool() {
      query("put", "addNewMachineTool", this.addSavedData, {
        token: localStorage.getItem("token"),
      }).then(() => {
        this.closeAddModal();
        this.tableUpdate();
      });
    },
    getShops() {
      query(
        "get",
        "getCurrentCompaniesShops",
        {},
        {
          token: localStorage.getItem("token"),
        },
      ).then((shops) => {
        this.shops = shops;
      });
    },
    saveAdd(name, value) {
      this.addSavedData[name] = value;
    },
    downloadList() {
      requestFile(
        "get",
        "expMachineToolsToExcel",
        {},
        {
          token: localStorage.getItem("token"),
        },
      ).then((item) => {
        const name = item.data.headers["content-disposition"];
        const VRegExp = new RegExp(/[^]*filename="([^]*)"/);
        const VResult = name.replace(VRegExp, "$1");
        this.fileSaver.saveAs(item.data.data, VResult);
      });
    },
    getAccuracyClasses() {
      query(
        "get",
        "getAccuracyClasses",
        {},
        {
          token: localStorage.getItem("token"),
        },
      ).then((res) => {
        this.accuracyClasses = res;
      });
    },
  },
  components: {
    PageConstructor,
    // Add,
    Edit,
    Modal,
    Search,
    Input,
    // Select,
    Button,
  },
};
</script>
