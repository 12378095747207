<template>
  <I5Modal :show="show" :title="text.edit_modal_name[language]" @close="onHandlerClose">
    <template slot="body">
      <div
        style="padding: 20px; overflow-y: scroll; height: calc(100% - 80px)"
        class="block block__column"
      >
        <div class="block" style="gap: 10px">
          <Input
            label="Наименование на русском"
            name="name_ru"
            :default-value="editData.name_ru"
            @change="(e) => onHandleChange(e, 'name_ru')"
            style="width: 100%"
          />
          <div>Режимы обработки</div>
        </div>
        <div class="block" style="gap: 10px">
          <Input
            label="Наименование (Eng)"
            name="name_en"
            :default-value="editData.name_en"
            @change="(e) => onHandleChange(e, 'name_en')"
            style="width: 100%"
          />
          <Input
            label="Наименование (De)"
            name="name_de"
            :default-value="editData.name_de"
            @change="(e) => onHandleChange(e, 'name_de')"
            style="width: 100%"
          />
        </div>
        <div class="block" style="gap: 10px">
          <Input
            label="Наименование (Tr)"
            name="name_tr"
            :default-value="editData.name_tr"
            @change="(e) => onHandleChange(e, 'name_tr')"
            style="width: 100%"
          />
          <Input
            label="Наименование (Zh)"
            name="name_zh"
            :default-value="editData.name_zh"
            @change="(e) => onHandleChange(e, 'name_zh')"
            style="width: 100%"
          />
        </div>
        <h3>Параметры</h3>
        <Search
          @change="addParam($event)"
          :query="{
            method: 'post',
            url: 'getAllToolNomenclatureTypeParameters',
            findName: 'find',
            label: 'name',
            value: 'id',
          }"
        />
        <div style="width: 100%" v-if="params && params.length > 0">
          <div
            v-for="(el, index) in params"
            v-bind:key="`${index}_param`"
            class="block block__between block__align-center"
            style="margin-bottom: 10px; border: 2px solid #f1f1f1; border-radius: 8px; padding: 5px"
          >
            <b>{{ el.name }}</b>
            <div class="tech-req__block_delete" @click="deleteParam(index)">
              <TrashIcon />
            </div>
          </div>
        </div>
      </div>
      <div
        style="height: 80px; gap: 12px; padding: 0 20px"
        class="block block__align-center block__right"
      >
        <Button type="default" @click="$emit('close')" text="Отмена" />
        <Button type="primary" text="Сохранить" @click="saveEdit" />
      </div>
    </template>
  </I5Modal>
</template>

<script>
import I5Modal from "@/components/i5Modal/I5Modal.vue";
import text from "@/assets/text/tools_nomenclature.json";
import Input from "@/components/input/Input.vue";
import TrashIcon from "@/assets/images/tech/trash.svg";
import Button from "@/components/button/Button.vue";
import Search from "@/components/search/Search.vue";
import { query } from "@/utilities/axios";
import CheckBoxI5 from "@/components/checkbox/CheckBoxI5.vue";

export default {
  name: "EditToolNomenclature",
  data() {
    return {
      text,
      editData: {
        name_ru: "",
        name_en: "",
        name_de: "",
        name_tr: "",
        name_zh: "",
      },
      params: [],
    };
  },
  components: {
    I5Modal,
    Input,
    TrashIcon,
    Button,
    Search,
    CheckBoxI5,
  },
  computed: {
    language() {
      return this.$store.state.index.language;
    },
  },
  props: {
    show: {
      type: Boolean,
    },
    parameters: {
      type: Array,
      default: () => [],
    },
    infoData: {
      type: Object,
    },
  },
  watch: {
    parameters: {
      immediate: true,
      handler: function (val) {
        this.params = val;
      },
    },
    infoData: {
      immediate: true,
      handler: function (val) {
        this.editData.name_ru = val.name_ru || "";
        this.editData.name_en = val.name_en || "";
        this.editData.name_de = val.name_de || "";
        this.editData.name_tr = val.name_tr || "";
        this.editData.name_zh = val.name_zh || "";
      },
    },
  },
  methods: {
    onHandlerClose() {
      this.$emit("close");
    },
    onHandleChange(e, name) {
      this.editData[name] = e;
    },
    deleteParam(index) {
      const params = [...this.params];
      params.splice(index, 1);
      this.params = params;
    },
    addParam(value) {
      this.params.unshift(value);
    },
    saveEdit() {
      const editData = {
        uuid: this.infoData.uuid,
        ...this.editData,
        id_tool_nomenclature_type_parameter: this.params.map((el) => el.id),
      };
      query("post", "editToolNomenclatureInfo", editData, {
        token: localStorage.getItem("token"),
      }).then(() => {
        this.$emit("update");
        this.$emit("close");
      });
    },
  },
};
</script>
