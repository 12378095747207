<template>
  <div class="home__wrapper">
    <div class="home__container">
      <div class="home__card">
        <div class="block block__between">
          <div>
            <div v-if="companyInfo.new_orders || companyInfo.new_orders === 0">
              <div class="home__card_title">
                {{ text.new_orders[language] }}
              </div>
              <div class="home__card_text">
                {{ companyInfo.new_orders }}
              </div>
            </div>
            <div v-if="companyInfo.total_out_orders || companyInfo.total_out_orders === 0">
              <div class="home__card_title">
                {{ text.total_orders[language] }}
              </div>
              <div class="home__card_text">
                {{ companyInfo.total_out_orders }}
              </div>
            </div>
            <div v-if="companyInfo.users || companyInfo.users === 0">
              <div class="home__card_title">
                {{ text.users[language] }}
              </div>
              <div class="home__card_text">
                {{ companyInfo.users }}
              </div>
            </div>
          </div>
          <div>
            <div v-if="companyInfo.total_machine_tools || companyInfo.total_machine_tools === 0">
              <div class="home__card_title">
                {{ text.machine_tools[language] }}
              </div>
              <div class="home__card_text">
                {{ companyInfo.total_machine_tools }}
              </div>
            </div>
            <div v-if="companyInfo.total_tools || companyInfo.total_tools === 0">
              <div class="home__card_title">
                {{ text.tools[language] }}
              </div>
              <div class="home__card_text">
                {{ companyInfo.total_tools }}
              </div>
            </div>
            <div
              v-if="
                companyInfo.total_measurement_tools || companyInfo.total_measurement_tools === 0
              "
            >
              <div class="home__card_title">
                {{ text.cmtools[language] }}
              </div>
              <div class="home__card_text">
                {{ companyInfo.total_measurement_tools }}
              </div>
            </div>
            <div v-if="companyInfo.total_blanks || companyInfo.total_blanks === 0">
              <div class="home__card_title">
                {{ text.blanks[language] }}
              </div>
              <div class="home__card_text">
                {{ companyInfo.total_blanks }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="home__card">
        <div class="home__calculator_title component__margin-bottom">
          {{ text.calc_title[language] }}
        </div>
        <div class="home__calculator_text component__margin-bottom">
          {{ text.calc_text[language] }}
        </div>
        <div>{{ text.calc_point_1[language] }}</div>
        <div>{{ text.calc_point_2[language] }}</div>
        <div>{{ text.calc_point_3[language] }}</div>
        <Button
          class="component__margin-top"
          :text="text.calc_button[language]"
          type="attention"
          @click="goToOrder"
          icon="calculator"
          block
        />
      </div>
    </div>
  </div>
</template>

<script>
import text from "@/assets/text/home.json";
import { query } from "@/utilities/axios";
import Button from "@/components/button/Button.vue";

export default {
  name: "Home",
  data() {
    return {
      text,
      orders: null,
      companyInfo: {},
      ordersLoading: false,
      orderYears: [],
      ordersChart: {
        labels: [
          "Январь",
          "Февраль",
          "Март",
          "Апрель",
          "Май",
          "Июнь",
          "Июль",
          "Август",
          "Сентябрь",
          "Октябрь",
          "Ноябрь",
          "Декабрь",
        ],
        datasets: [
          {
            label: "",
            backgroundColor: "#c32844",
            data: [],
          },
        ],
      },
    };
  },
  computed: {
    language() {
      return this.$store.state.index.language;
    },
  },
  methods: {
    getOrdersChartByYear(data, year) {
      const dateOrders = {};
      for (const order of data) {
        const arrayDate = order.date.split(".");
        const date = new Date(arrayDate[2], arrayDate[1]);
        let value = 1;
        if (dateOrders[date.getFullYear()] && dateOrders[date.getFullYear()][date.getMonth()]) {
          value = dateOrders[date.getFullYear()][date.getMonth()] + 1;
        }
        dateOrders[date.getFullYear()] = {
          ...dateOrders[date.getFullYear()],
          ...{ [date.getMonth()]: value },
        };
      }
      const result = [];
      for (const [index] of this.ordersChart.labels.entries()) {
        if (dateOrders[year][index + 1]) {
          result.push(dateOrders[year][index + 1]);
        }
      }
      this.orderYears = Object.keys(dateOrders);
      this.ordersChart.datasets[0].data = result;
      this.ordersChart.datasets[0].label = `Заказы за ${year} г.`;
      this.ordersLoading = true;
    },
    getOrders() {
      query(
        "get",
        "getOrdersAsManufacturer",
        {},
        {
          token: localStorage.getItem("token"),
        },
      ).then((orders) => {
        this.orders = orders;
        this.getOrdersChartByYear(orders, +new Date().getFullYear() - 1);
      });
    },
    getCompanyFigures() {
      query(
        "get",
        "getCompanyFigures",
        {},
        {
          token: localStorage.getItem("token"),
        },
      ).then((figures) => {
        this.companyInfo = figures.company_info;
      });
    },
    goToOrder() {
      this.$router.push("/create_order/draft");
    },
  },
  created() {
    this.getOrders();
    this.getCompanyFigures();
  },
  components: {
    Button,
  },
};
</script>
