<template>
  <div class="tech-req__wrapper">
    <div class="tech-req__left_wrapper">
      <div class="tech-req__left_menu">
        <div class="tech-req__left_menu_items">
          <div class="tech-req__left_menu_title">
            {{ tech.menu_title[language] }}
          </div>
          <div
            v-for="(item, index) in Object.keys(menuValues).filter((val) =>
              this.filterMenu(menuValues[val]),
            )"
            v-bind:key="index"
          >
            <TechMenuItem
              :value="menuValues[item].value"
              :index="+index + 1"
              :active="menuValues[item].active"
              :disabled="menuValues[item].disabled"
              :id="index"
              @click="setActiveMenu($event)"
            />
          </div>
        </div>
        <div class="tech-req__left_menu_static">
          <div class="tech-req__menu-item tech-req__menu-item_exit" @click="closePage">
            {{ tech.menu_exit[language] }}
          </div>
        </div>
      </div>
      <div class="tech-req__left_params">
        <MaterialAndBlank
          :edit="edit"
          :part-data="partData"
          v-if="menuValues[activeMenu].type === 'material'"
        />
        <Roughness
          :edit="edit"
          v-if="menuValues[activeMenu].type === 'roughness' && partData"
          :part-data="partData"
        />
        <Hardness
          :edit="edit"
          :part-data="partData"
          v-if="menuValues[activeMenu].type === 'hardness'"
        />
        <Features
          :edit="edit"
          v-if="menuValues[activeMenu].type === 'features' && partData"
          :part-data="partData"
        />
        <RoughnessV2
          :edit="edit"
          v-if="menuValues[activeMenu].type === 'roughnessV2' && partData"
          :part-data="partData"
        />
        <AccPosition
          :edit="edit"
          :part-data="partData"
          v-if="menuValues[activeMenu].type === 'position' && partData"
        />
        <AccShape
          :edit="edit"
          :part-data="partData"
          v-if="menuValues[activeMenu].type === 'shape' && partData"
        />
        <AccSize
          :edit="edit"
          :part-data="partData"
          v-if="menuValues[activeMenu].type === 'size' && partData"
        />
        <Gears :edit="edit" :part-data="partData" v-if="menuValues[activeMenu].type === 'gears'" />
        <Threads
          :edit="edit"
          :part-data="partData"
          v-if="menuValues[activeMenu].type === 'threads'"
        />
      </div>
    </div>
    <div class="tech-req__model_wrapper">
      <ModelViewer
        :select-type="$store.state.tech.selectType"
        v-if="link_3d"
        :showBoxShadow="true"
        :selected="returnSelectedSurfaces()"
        :activeGroup="$store.state.tech.activeIndex"
        :colorize="$store.state.tech.colorize"
        :defaultGroups="$store.state.tech.defaultGroups"
        @select="setSelectedSurfaces($event)"
        type="gltf"
        :src="link_3d"
      />
    </div>
  </div>
</template>

<script>
import packageJson from "@/../package.json";
import tech from "@/assets/text/tech.json";
import { query } from "@/utilities/axios";

const TechMenuItem = () => import("./components/MenuItem.vue");
const ModelViewer = () => import("@/components/modelViewer/ModelViewer.vue");
const MaterialAndBlank = () => import("./components/MaterialAndBlank.vue");
const Roughness = () => import("./components/Roughness.vue");
const RoughnessV2 = () => import("./components/RoughnessV2.vue");
const Hardness = () => import("./components/HardnessV2.vue");
const Features = () => import("./components/Features.vue");
const AccPosition = () => import("./components/AccuracyPosition.vue");
const AccShape = () => import("./components/AccuracyShape.vue");
const AccSize = () => import("./components/AccuracySize.vue");
const Gears = () => import("./components/GearsV2.vue");
const Threads = () => import("./components/ThreadsV2.vue");

export default {
  name: "TechnicalRequirements",
  data() {
    return {
      packageJson,
      tech,
      hash: null,
      file_name: null,
      ext: null,
      time_stamp: null,
      activeMenu: 0,
      link_3d: null,
      partData: null,
      edit: false,
      menuValues: {
        0: {
          value: tech.menu_material,
          type: "material",
          active: true,
          disabled: false,
          expert: false,
          admin: false,
        },
        1: {
          value: tech.menu_roughness,
          type: "roughnessV2",
          active: false,
          disabled: false,
          expert: false,
          admin: false,
        },
        2: {
          value: tech.menu_relative_shape,
          type: "shape",
          active: false,
          disabled: false,
          expert: false,
          admin: false,
        },
        3: {
          value: tech.menu_relative_position,
          type: "position",
          active: false,
          disabled: false,
          expert: false,
          admin: false,
        },
        4: {
          value: tech.menu_hardness,
          type: "hardness",
          active: false,
          disabled: false,
          expert: false,
          admin: false,
        },
        5: {
          value: tech.menu_threads,
          type: "threads",
          active: false,
          disabled: false,
          expert: false,
          admin: false,
        },
        6: {
          value: tech.menu_gears,
          type: "gears",
          active: false,
          disabled: false,
          expert: false,
          admin: false,
        },
        7: {
          value: tech.features_title,
          type: "features",
          active: false,
          disabled: false,
          expert: true,
          admin: false,
        },
      },
    };
  },
  computed: {
    language() {
      return this.$store.state.index.language;
    },
    access() {
      return this.$store.state.user.access;
    },
  },
  methods: {
    setActiveMenu(id) {
      if (this.menuValues[id].active === false) {
        this.menuValues[id].active = true;
        this.menuValues[this.activeMenu].active = false;
        this.activeMenu = id;
        this.$store.state.modelV2.clearSurfaces();
      }
    },
    filterMenu(val) {
      let result = true;
      if (val.expert && !this.access.expert) {
        result = false;
      }
      if (val.admin && !this.access.admin) {
        result = false;
      }
      return result;
    },
    closePage() {
      this.$router.back();
    },
    setSelectedSurfaces(data) {
      this.$store.commit("tech/setTempSurfaces", data);
    },
    returnSelectedSurfaces() {
      const tech = this.$store.state.tech;
      let result = [];
      if (
        tech.surfacesRoughness[tech.activeIndex[tech.activeIndex.length - 1]] &&
        tech.surfacesRoughness[tech.activeIndex[tech.activeIndex.length - 1]].surfaces
      ) {
        result = tech.surfacesRoughness[tech.activeIndex[tech.activeIndex.length - 1]].surfaces;
      }
      return result;
    },
    getPart(uuid) {
      query(
        "post",
        "getTechDataByPartOrder",
        {
          uuid,
        },
        {
          token: localStorage.getItem("token"),
        },
      ).then((result) => {
        this.edit = result.edit;
        this.partData = result.file_3d;
        this.link_3d = result.file_3d.fileEdit3d;
      });
    },
  },
  /*
  beforeRouteEnter(to, from, next) {
    if (!to.query.h || !to.query.t || !to.query.n) {
      next(from.path);
    } else {
      next((vm) => {
        if (to.query.token) {
          localStorage.setItem('token', to.query.token);
        }
        vm.$store.dispatch('user/getUser').then(() => {
          vm.$store.commit('tech/setPartId', {
            hash: to.query.h,
            time_stamp: to.query.t,
            file_name: to.query.n,
            ext: to.query.e,
          });
          vm.$store.dispatch('tech/getDataForUploadedFile', {
            data: {
              hash: to.query.h,
              time_stamp: to.query.t,
              file_name: to.query.n,
            },
          });
          const component = vm;
          component.hash = to.query.h;
          component.file_name = to.query.n;
          component.ext = to.query.e;
          component.time_stamp = to.query.t;
        }).catch(() => {
          next(from.path);
        });
      });
    }
  }, */
  mounted() {
    for (const i of Object.keys(this.menuValues)) {
      if (this.menuValues[i].type === this.$route.params.page) {
        this.activeMenu = i;
      }
    }
    this.getPart(this.$route.params.uuid);
  },
  components: {
    Hardness,
    TechMenuItem,
    ModelViewer,
    MaterialAndBlank,
    Roughness,
    RoughnessV2,
    Features,
    AccPosition,
    AccShape,
    AccSize,
    Gears,
    Threads,
  },
};
</script>
