<template>
  <div>
    <Modal
      :show="showEditModal"
      :title="text.edit_title[language]"
      :ok-text="text.save"
      @ok="editShop"
      @cancel="setShowEditModal(false)"
    >
      <template slot="body">
        <div style="padding: 20px">
          <div style="width: 100%">
            <Input
              @change="saveEditData($event, 'name')"
              :default-value="row.name ? row.name : ''"
              :label="text.name[language]"
            />
          </div>
          <div style="width: 100%">
            <Select
              @change="saveEditData($event.value, 'id_processing_method')"
              :values="shopTypes"
              :dropdown-props="{
                label: 'label',
                value: 'value',
              }"
              :position-type="'fixed'"
              :default-value="row.shop_type ? row.shop_type : ''"
              :label="text.shop_type[language]"
            />
          </div>
          <div style="width: 100%">
            <Select
              :position-type="'fixed'"
              @change="changeCountry($event.value)"
              :values="countries"
              :dropdown-props="{
                label: 'name',
                value: 'id',
              }"
              v-if="countries && countries[editCountryIndex]"
              :default-value="countries[editCountryIndex].name"
              :label="text.country[language]"
            />
          </div>
          <div style="width: 100%">
            <Select
              :position-type="'fixed'"
              @change="saveEditData($event.value, 'id_address_region')"
              :values="regions"
              :dropdown-props="{
                label: 'name',
                value: 'id',
              }"
              :default-value="row.address ? row.address : ''"
              :label="text.place[language]"
            />
          </div>
        </div>
      </template>
    </Modal>
    <Modal
      v-if="showDeleteModal"
      :show="showDeleteModal"
      :title="text.delete_title[language]"
      :ok-text="text.delete"
      @ok="deleteWorkshop"
      @cancel="setShowDeleteModal(false)"
    >
      <template slot="body">
        <div style="padding: 20px" v-if="row && row.name">
          <span
            >{{ text.delete_text[language] }}: <b>{{ row.name }}</b> ?</span
          >
        </div>
      </template>
    </Modal>
    <div class="page__edit_large-title page__edit_margin">
      {{ text.large_title[language] }}
    </div>
    <Button
      @click="setShowEditModal(true)"
      class="page__edit_margin"
      :text="text.edit[language]"
      block
    />
    <Button
      @click="setShowDeleteModal(true)"
      class="page__edit_margin"
      :text="text.delete[language]"
      block
    />
    <div class="page__edit_title">{{ text.name[language] }}:</div>
    <div class="page__edit_margin page__edit_value">
      {{ row.name }}
    </div>
    <div class="page__edit_title">{{ text.place[language] }}:</div>
    <div class="page__edit_margin page__edit_value">
      {{ row.address }}
    </div>
    <div class="page__edit_title">{{ text.shop_type[language] }}:</div>
    <div class="page__edit_margin page__edit_value">
      {{ row.shop_type }}
    </div>
  </div>
</template>

<script>
import text from "@/assets/text/workshops.json";
import Button from "@/components/button/Button.vue";
import Modal from "@/components/modal/Modal.vue";
import Input from "@/components/input/Input.vue";
import Select from "@/components/select/Select.vue";
import { query } from "@/utilities/axios";

export default {
  name: "EditWorkshop",
  data() {
    return {
      text,
      showDeleteModal: false,
      showEditModal: false,
      shopTypes: [],
      regions: [],
      countries: [],
      editCountryId: 1,
      editCountryIndex: 0,
      editData: {},
    };
  },
  props: {
    row: {
      type: Object,
    },
  },
  watch: {
    showEditModal: function (val) {
      if (val && (this.regions.length === 0 || this.shopTypes.length === 0)) {
        this.getShopTypes();
        this.getCountries();
        this.getRegions();
      }
    },
    language: function () {
      this.getRegions();
      this.getShopTypes();
    },
    editCountryId: function () {
      this.getRegions();
    },
  },
  computed: {
    language() {
      return this.$store.state.index.language;
    },
  },
  methods: {
    getShopTypes() {
      query(
        "get",
        "getShopTypes",
        {},
        {
          token: localStorage.getItem("token"),
        },
      ).then((res) => {
        const result = [];
        if (this.language === "ru") {
          res.forEach((el) => {
            result.push({
              value: el.id,
              label: el.name,
            });
          });
        } else {
          res.forEach((el) => {
            result.push({
              value: el.id,
              label: el.name_eng,
            });
          });
        }
        this.shopTypes = result;
      });
    },
    getRegions() {
      query(
        "get",
        "getRegions",
        {},
        {
          lang: this.language === "ru" ? "rus" : "eng",
          id_address_country: this.editCountryId,
          token: localStorage.getItem("token"),
        },
      ).then((res) => {
        this.regions = res;
      });
    },
    getCountries() {
      query(
        "get",
        "getCountries",
        {},
        {
          token: localStorage.getItem("token"),
        },
      ).then((res) => {
        this.countries = res;
        const index = this.countries.findIndex((el) => el.id === 1);
        this.editCountryIndex = index;
      });
    },
    setShowDeleteModal(val) {
      this.showDeleteModal = val;
    },
    setShowEditModal(val) {
      this.showEditModal = val;
    },
    saveEditData(value, name) {
      this.editData[name] = value;
    },
    changeCountry(value) {
      this.editCountryId = value;
    },
    editShop() {
      let data = {
        id: this.row.id,
      };
      data = { ...data, ...this.editData };
      query("post", "changeWorkShopInfo", data, {
        token: localStorage.getItem("token"),
      }).then(() => {
        this.showEditModal = false;
        this.$emit("update");
      });
    },
    deleteWorkshop() {
      const data = {
        id: this.row.id,
      };
      query("post", "deleteWorkShop", data, {
        token: localStorage.getItem("token"),
      })
        .then(() => {
          this.showDeleteModal = false;
          this.$emit("update");
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  components: {
    Modal,
    Button,
    Input,
    Select,
  },
};
</script>
