<template>
  <div>
    <div class="page__edit_margin page__edit_large-title">{{ text.edit_name[language] }}</div>
    <div v-if="row">
      <div v-if="row.img" class="block block__center">
        <img :src="row.img" class="page__edit_img" />
      </div>

      <div class="page__edit_margin" v-if="row.name">
        <div class="page__edit_title">{{ text.name[language] }}:</div>
        <div class="page__edit_value">
          {{ row.name }}
        </div>
      </div>

      <div class="page__edit_margin" v-if="row.type">
        <div class="page__edit_title">{{ text.type[language] }}:</div>
        <div class="page__edit_value">
          {{ row.type }}
        </div>
      </div>

      <div class="page__edit_margin" v-if="row.done">
        <div class="page__edit_title">{{ text.done[language] }}:</div>
        <div class="page__edit_value">
          {{ row.done }}
        </div>
      </div>

      <div class="page__edit_margin" v-if="row.inner_size">
        <div class="page__edit_title">{{ text.inner_size[language] }}:</div>
        <div class="page__edit_value">
          {{ row.inner_size }}
        </div>
      </div>

      <div class="page__edit_margin" v-if="row.amount_working_bodies">
        <div class="page__edit_title">{{ text.amount_working_bodies[language] }}:</div>
        <div class="page__edit_value">
          {{ row.amount_working_bodies }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import text from "@/assets/text/fixture-catalogue.json";

export default {
  name: "Edit",
  data() {
    return {
      text,
    };
  },
  props: {
    row: {
      type: Object,
    },
  },
  computed: {
    language() {
      return this.$store.state.index.language;
    },
  },
};
</script>
