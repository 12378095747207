<template>
  <div>
    <EditModal
      @close="setShowEditModal(false)"
      :show="showEditModal"
      :infoData="infoData"
      :parameters="(infoData && infoData.parameters) || []"
    />
    <div class="page__edit_margin page__edit_large-title">{{ text.edit_name[language] }}</div>
    <Button
      @click="setShowEditModal(true)"
      class="page__edit_margin"
      type="default"
      :text="text.edit_button[language]"
      block
    />
    <div v-if="row">
      <div class="page__edit_title">{{ text.name[language] }}:</div>
      <div v-if="row.name" class="page__edit_margin page__edit_value">
        {{ row.name }}
      </div>
    </div>
    <div v-if="row.img" class="block block__center">
      <img :src="row.img" class="page__edit_img" />
    </div>
    <div
      class="page__edit_margin"
      v-if="infoData && infoData.parameters && infoData.parameters.length > 0"
    >
      <div class="page__edit_title">{{ text.parameters[language] }}:</div>
      <div class="page__edit_line">
        <div
          v-for="param in infoData.parameters"
          v-bind:key="param.uuid"
          style="margin-bottom: 10px"
        >
          <div class="page__edit_line_value">
            {{ param.name }}{{ param.dimension && `, ${param.dimension}` }}
          </div>
          <div class="page__edit_line_label" v-if="param.iso302_01">
            iso302 01: <b>{{ param.iso302_01 }}</b>
          </div>
          <div class="page__edit_line_label" v-if="param.gtc_drlfss">
            gtc drlfss: <b>{{ param.gtc_drlfss }}</b>
          </div>
          <div class="page__edit_line_label" v-if="param.din_4000_bnn1">
            din 4000 bnn1: <b>{{ param.din_4000_bnn1 }}</b>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import text from "@/assets/text/tools_nomenclature.json";
import query from "@/utilities/axios";
import EditModal from "../modals/EditModal.vue";
import Button from "@/components/button/Button.vue";

export default {
  name: "Edit",
  data() {
    return {
      text,
      infoData: null,
      showEditModal: false,
    };
  },
  props: {
    row: {
      type: Object,
    },
  },
  watch: {
    row: {
      immediate: true,
      handler: function (val) {
        if (val.uuid) {
          this.getToolType(val.uuid);
        }
      },
    },
  },
  methods: {
    getToolType(uuid) {
      query(
        "post",
        "getToolNomenclatureInfo",
        { uuid },
        {
          token: localStorage.getItem("token"),
        },
      ).then((result) => {
        this.infoData = result;
      });
    },
    setShowEditModal(val) {
      this.showEditModal = val;
    },
  },
  computed: {
    language() {
      return this.$store.state.index.language;
    },
  },
  components: {
    EditModal,
    Button,
  },
};
</script>
