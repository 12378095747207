var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"margin-left":"8px","padding-bottom":"20px","width":"100%"}},[_c('div',{staticClass:"page-header__name block block__align-center",staticStyle:{"height":"43px","margin-bottom":"20px"}},[_vm._v(" "+_vm._s(_vm.text.title[_vm.language])+" ")]),_c('div',{staticClass:"block"},[_c('div',{staticClass:"home__card"},[(_vm.showChart)?_c('Bar',{attrs:{"data":_vm.chartData,"width":900,"options":{
          scales: {
            xAxes: [
              {
                barThickness: 6, // number (pixels) or 'flex'
                maxBarThickness: 8, // number (pixels)
              } ],
          },
        }}}):_vm._e()],1),_c('div',{staticStyle:{"width":"100%"}}),_c('div')])])}
var staticRenderFns = []

export { render, staticRenderFns }