<template>
  <div>
    <Modal
      :title="text.edit_title[language]"
      :ok-text="text.save"
      @ok="changeCompany"
      @cancel="setCompanyModal(false)"
      :show="showCompanyModal"
    >
      <template slot="body">
        <div style="padding: 20px" v-if="row">
          <Input
            @change="saveEditData($event, 'email')"
            :label="text.email[language]"
            :default-value="row.email ? row.email : ''"
          />
          <Textarea
            @change="saveEditData($event, 'desc')"
            :default-value="row.description ? row.description : ''"
            :label="text.desc[language]"
          />
          <Textarea
            @change="saveEditData($event, 'comment')"
            :default-value="row.comment ? row.comment : ''"
            :label="text.comment[language]"
          />
        </div>
      </template>
    </Modal>
    <div class="page__edit_margin page__edit_large-title">{{ text.edit_name[language] }}</div>
    <div v-if="company">
      <Button
        block
        :text="text.edit[language]"
        class="page__edit_margin"
        @click="setCompanyModal(true)"
      />
      <a href="https://market.i5.solutions/company">
        <Button block :text="text.view[language]" class="page__edit_margin" />
      </a>
      <div v-if="company.logo" class="page__edit_margin">
        <img
          :src="company.logo"
          style="max-height: 40px; max-width: 100px; object-fit: scale-down"
        />
      </div>
      <div v-if="company.name" class="page__edit_margin">
        <div class="page__edit_title">{{ text.name[language] }}:</div>
        <div class="page__edit_value component__select">
          {{ company.name }}
        </div>
        <div class="page__edit_value component__select" v-if="company.name_eng">
          {{ company.name_eng }}
        </div>
      </div>
      <div class="page__edit_margin">
        <div class="page__edit_title component__margin-bottom">
          {{ text.get_orders[language] }}:
        </div>
        <a-switch @change="changeReceiveOrders($event)" :default-checked="company.receive_orders" />
      </div>
      <div v-if="company.inn" class="page__edit_margin">
        <div class="page__edit_title">{{ text.tin[language] }}:</div>
        <div class="page__edit_value component__select">
          {{ company.inn }}
        </div>
      </div>
      <div v-if="company.company_reg_number" class="page__edit_margin">
        <div class="page__edit_title">{{ text.reg_number[language] }}:</div>
        <div class="page__edit_value component__select">
          {{ company.company_reg_number }}
        </div>
      </div>
      <div v-if="company.link" class="page__edit_margin">
        <div class="page__edit_title">{{ text.link[language] }}:</div>
        <a :href="company.link">{{ company.link }}</a>
      </div>
      <div v-if="company.email" class="page__edit_margin">
        <div class="page__edit_title component__select">{{ text.email[language] }}:</div>
        <a :href="`mailto:${company.email}`">{{ company.email }}</a>
      </div>
      <div v-if="company.fio_ceo" class="page__edit_margin">
        <div class="page__edit_title">{{ text.ceo[language] }}:</div>
        <div class="page__edit_value component__select" v-if="company.position">
          {{ company.position }}
        </div>
        <div class="page__edit_value component__select">
          {{ company.fio_ceo }}
        </div>
      </div>
      <div v-if="row" class="page__edit_margin">
        <div class="page__edit_title">{{ text.license_date_limit[language] }}:</div>
        <div class="page__edit_value component__select">
          <div class="block__flex">
            <DropdownV3>
              <template slot="body">
                <div class="block__flex block__align-center">
                  <div style="text-decoration: underline; cursor: pointer">
                    {{
                      row.license_date_limit
                        ? moment(row.license_date_limit).format("DD.MM.YYYY")
                        : text.not_set[language]
                    }}
                  </div>
                </div>
              </template>
              <template slot="dropdown">
                <Calendar
                  @setDate="changeLicenseDate($event)"
                  :default-date="
                    row.license_date_limit
                      ? moment(row.license_date_limit).format('DD.MM.YYYY')
                      : null
                  "
                />
              </template>
            </DropdownV3>
            <div
              style="cursor: pointer"
              class="component__margin-left"
              v-if="row.license_date_limit"
              @click="changeLicenseDate(null)"
            >
              <DeleteLogo />
            </div>
          </div>
        </div>
      </div>
      <div v-if="row && row.description" class="page__edit_margin">
        <div class="page__edit_title">{{ text.desc[language] }}:</div>
        <div class="page__edit_value component__select">
          {{ row.description }}
        </div>
      </div>
      <div v-if="row && row.comment" class="page__edit_margin">
        <div class="page__edit_title">{{ text.comment[language] }}:</div>
        <div class="page__edit_value component__select">
          {{ row.comment }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import text from "@/assets/text/all_organizations.json";
import { query } from "@/utilities/axios";
import moment from "moment";
import Calendar from "@/components/calendar/Calendar.vue";
import DropdownV3 from "@/components/dropdownV3/DropdownV3.vue";
import DeleteLogo from "@/assets/images/parts/delete-file.svg";
import Modal from "@/components/modal/Modal.vue";
import Input from "@/components/input/Input.vue";
import Textarea from "@/components/textarea/Textarea.vue";
import Button from "@/components/button/Button.vue";

export default {
  name: "Edit",
  data() {
    return {
      text,
      moment,
      company: null,
      showCompanyModal: false,
      editData: {},
    };
  },
  props: {
    row: {
      type: Object,
    },
  },
  watch: {
    row: function (val) {
      this.getCompany(val.id);
    },
  },
  computed: {
    language() {
      return this.$store.state.index.language;
    },
  },
  methods: {
    getCompany(uuid) {
      query(
        "post",
        "getCompanyInfoAdmin",
        {
          id_company: uuid,
        },
        {
          token: localStorage.getItem("token"),
        },
      ).then((res) => {
        this.company = res[0];
      });
    },
    changeReceiveOrders(value) {
      query(
        "post",
        "changeCompanyInfoByAdmin",
        {
          id_company: this.row.id,
          receive_orders: value,
        },
        {
          token: localStorage.getItem("token"),
        },
      ).then(() => {
        this.$emit("update");
      });
    },
    changeLicenseDate(value) {
      let resultDate = null;
      if (value) {
        const splitDate = value.split(".");
        resultDate = `${splitDate[2]}.${splitDate[1]}.${splitDate[0]}`;
      }
      query(
        "post",
        "changeCompanyInfoByAdmin",
        {
          id_company: this.row.id,
          license_date_limit: resultDate,
        },
        {
          token: localStorage.getItem("token"),
        },
      ).then(() => {
        this.$emit("update");
      });
    },
    setCompanyModal(val) {
      this.showCompanyModal = val;
    },
    changeCompany() {
      let data = {
        id_company: this.row.id,
      };
      data = { ...data, ...this.editData };
      query("post", "changeCompanyInfoByAdmin", data, {
        token: localStorage.getItem("token"),
      }).then(() => {
        this.setCompanyModal(false);
        this.$emit("update");
      });
    },
    saveEditData(value, name) {
      this.editData[name] = value;
    },
  },
  mounted() {
    this.getCompany(this.row.id);
  },
  components: {
    DropdownV3,
    Calendar,
    DeleteLogo,
    Button,
    Modal,
    Input,
    Textarea,
  },
};
</script>
