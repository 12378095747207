var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Modal',{attrs:{"show":_vm.show,"title":_vm.setTitle(_vm.type)},on:{"close":_vm.handleClose}},[_c('template',{slot:"body"},[_c('div',{staticClass:"blank__add_wrapper"},[_c('div',{staticClass:"blank__add_container"},[_c('div',{staticClass:"blank__add_left"},[_c('div',{staticClass:"blank__add_card",staticStyle:{"width":"100%"}},[_c('div',{staticClass:"block"},[_c('div',{staticStyle:{"width":"100%"}},[_c('Search',{attrs:{"label":_vm.text.material[_vm.language],"query":{
                    method: 'post',
                    url: 'getMaterialsAsArray',
                    label: 'name',
                    value: 'id',
                  },"required":true,"default-value":_vm.values && _vm.values.material ? _vm.values.material : '',"clearable":"","name":"material"},on:{"change":function($event){return _vm.handleChange('id_material', $event.value)}}}),_c('Search',{attrs:{"label":_vm.text.blank[_vm.language],"default-value":_vm.values && _vm.values.blank_type ? _vm.values.blank_type : '',"query":{
                    method: 'post',
                    url: 'getSearchBlanks',
                    label: 'blank_type',
                    value: 'id',
                  },"clearable":"","name":"blank_type"},on:{"change":function($event){return _vm.handleChange('id_blank_type', $event.value)}}})],1),_c('div',{staticStyle:{"width":"100%"}},[_c('Search',{staticStyle:{"margin-left":"20px"},attrs:{"label":_vm.text.material_standard[_vm.language],"query":{
                    method: 'post',
                    url: 'getSearchStandards',
                    label: 'standard_code',
                    value: 'id',
                  },"clearable":"","name":"gost_material"},on:{"change":function($event){return _vm.handleChange('id_gost_material', $event.value)}}}),_c('Search',{staticStyle:{"margin-left":"20px"},attrs:{"label":_vm.text.blank_standard[_vm.language],"query":{
                    method: 'post',
                    url: 'getSearchStandards',
                    label: 'standard_code',
                    value: 'id',
                  },"ti":11,"clearable":"","name":"gost_blank"},on:{"change":function($event){return _vm.handleChange('id_gost_blank', $event.value)}}})],1)])]),_c('div',{staticClass:"block"},[_c('div',{staticClass:"blank__add_card",staticStyle:{"margin-right":"10px"}},[_c('div',[(_vm.values && _vm.values.img)?_c('div',[_c('img',{staticStyle:{"max-width":"100%"},attrs:{"src":_vm.values.img}})]):_vm._e(),(_vm.values && _vm.values.blank_sizes)?_c('div',{staticStyle:{"width":"100%"}},_vm._l((Object.keys(_vm.values.blank_sizes)),function(size,i){return _c('div',{key:("sizes_" + i),staticStyle:{"width":"100%"}},[_c('div',{staticClass:"block__flex block__align-center",staticStyle:{"margin-bottom":"10px"}},[_c('div',{staticClass:"blank__add_letter",staticStyle:{"margin-right":"10px","min-width":"30px","text-align":"right"}},[_vm._v(" "+_vm._s(size)+" ")]),_c('Input',{attrs:{"max-width":80,"default-value":_vm.values.blank_sizes[size],"margin-bottom":0}}),_c('div',{staticClass:"blank__add_letter",staticStyle:{"margin-left":"10px"}},[_vm._v(" "+_vm._s(_vm.text.mm[_vm.language])+" ")])],1)])}),0):_vm._e()])]),_c('div',{staticClass:"blank__add_card",staticStyle:{"width":"100%"}},[_c('div',{staticClass:"block"},[_c('div',[_c('div',{staticClass:"blank__add_title"},[_vm._v(" "+_vm._s(_vm.text.amount[_vm.language])+" ")]),_c('Input',{attrs:{"type":"number","margin-bottom":0,"default-value":_vm.values && _vm.values.amount_blanks_value ? _vm.values.amount_blanks_value : ''}})],1),_c('div')])])])]),_c('div',{staticClass:"blank__add_right"})]),_c('div',{staticClass:"blank__add_bottom"},[_c('Button',{attrs:{"type":"default","text":_vm.text.cancel[_vm.language]}}),_c('Button',{staticStyle:{"margin-left":"20px"},attrs:{"type":"primary","text":_vm.text.save[_vm.language]}})],1)])])],2)}
var staticRenderFns = []

export { render, staticRenderFns }