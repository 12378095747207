<template>
  <div>
    <div class="page__edit_margin page__edit_large-title">{{ text.edit_name[language] }}</div>
    <EditModal
      @close="setShowEditModal(false)"
      :show="showEditModal"
      :parameters="infoData.param || []"
    />
    <div v-if="row">
      <Button
        @click="setShowEditModal(true)"
        class="page__edit_margin"
        type="default"
        :text="text.edit_button[language]"
        block
      />
      <div v-if="row.img" class="block block__center">
        <img :src="row.img" class="page__edit_img" />
      </div>

      <div class="page__edit_margin" v-if="row.name">
        <div class="page__edit_title">{{ text.name[language] }}:</div>
        <div class="page__edit_value">
          {{ row.name }}
        </div>
      </div>

      <div class="page__edit_margin" v-if="row.description">
        <div class="page__edit_title">{{ text.description[language] }}:</div>
        <div class="page__edit_value">
          {{ row.description }}
        </div>
      </div>

      <div class="page__edit_margin" v-if="infoData && infoData.param && infoData.param.length > 0">
        <div class="page__edit_title">{{ text.amount_parameters[language] }}:</div>
        <div class="page__edit_line">
          <div v-for="param in infoData.param" v-bind:key="param.uuid">
            <div class="page__edit_line_label">
              {{ param.name }}
            </div>
          </div>
        </div>
      </div>

      <div class="page__edit_margin" v-if="infoData && infoData.wb && infoData.wb.length > 0">
        <div class="page__edit_title">{{ text.amount_working_bodies[language] }}:</div>
        <div class="page__edit_line">
          <div v-for="param in infoData.wb" v-bind:key="param.uuid">
            <div class="page__edit_line_label">
              {{ param.name }}
            </div>
          </div>
        </div>
      </div>

      <div
        class="page__edit_margin"
        v-if="infoData && infoData.fixture_schemas && infoData.fixture_schemas.length > 0"
      >
        <div class="page__edit_title">{{ text.amount_fixture_schemas[language] }}:</div>
        <div class="page__edit_line">
          <div v-for="param in infoData.fixture_schemas" v-bind:key="param.uuid">
            <div class="page__edit_line_label">
              {{ param.name }}
            </div>
          </div>
        </div>
      </div>

      <div class="page__edit_margin" v-if="row.amount_mto">
        <div class="page__edit_title">{{ text.amount_mto[language] }}:</div>
        <div class="page__edit_value">
          {{ row.amount_mto }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import text from "@/assets/text/mt_nomenclature.json";
import { query } from "@/utilities/axios/index";
import EditModal from "../modals/EditModal.vue";
import Button from "@/components/button/Button.vue";

export default {
  name: "Edit",
  data() {
    return {
      text,
      infoData: null,
      showEditModal: false,
    };
  },
  props: {
    row: {
      type: Object,
    },
  },
  watch: {
    row: {
      type: "Object",
      immediate: true,
      handler: function (val) {
        if (val.uuid) {
          this.getInfo(val.uuid);
        }
      },
    },
  },
  methods: {
    getInfo(uuid) {
      query(
        "post",
        "getMachineToolTypeInfo",
        { uuid },
        {
          token: localStorage.getItem("token"),
        },
      ).then((result) => {
        this.infoData = result;
      });
    },
    setShowEditModal(val) {
      this.showEditModal = val;
    },
  },
  computed: {
    language() {
      return this.$store.state.index.language;
    },
  },
  components: {
    EditModal,
    Button,
  },
};
</script>
