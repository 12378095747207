var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"block__flex block__align-center block__column",staticStyle:{"position":"relative"}},[_c('div',{class:("input-i5 " + (_vm.setFocus())),staticStyle:{"width":"100%"}},[_c('label',{attrs:{"for":_vm.name},on:{"click":_vm.handleFocusOut}},[_vm._v(_vm._s(_vm.label)+" "),(_vm.required)?_c('span',[_vm._v("*")]):_vm._e(),(_vm.subLabel && !_vm.error)?_c('span',{staticClass:"input-i5__sublabel"},[_vm._v(" "+_vm._s(_vm.subLabel)+" ")]):_vm._e(),(_vm.error)?_c('span',{staticClass:"input-i5__error"},[_vm._v(" "+_vm._s(_vm.error)+" ")]):_vm._e()]),_c('div',{staticStyle:{"position":"relative"}},[_c('input',{class:("" + (_vm.error ? 'input-i5_error' : '')),style:(((_vm.clearable ? 'padding-right: 20px;' : '') + " height: " + _vm.height + "px !important; margin-bottom: " + _vm.marginBottom + "px !important; " + (_vm.maxWidth ? ("max-width: " + _vm.maxWidth + "px;") : ''))),attrs:{"tabindex":_vm.ti,"id":_vm.name,"name":_vm.name,"autocomplete":"off","readonly":"","disabled":_vm.disabled === true},domProps:{"value":_vm.value},on:{"focus":_vm.handleFocus,"blur":_vm.handleFocusOut,"click":_vm.handleInputClick,"input":_vm.handleChange}}),(_vm.clearable && _vm.value)?_c('ClearImage',{staticClass:"input-i5__clear",on:{"click":_vm.clear}}):_vm._e()],1)]),_c('div',{class:("search__container " + (_vm.setOpen())),style:({
      width: (_vm.elWidth + "px"),
      maxHeight: (_vm.dropDownMaxHeight + "px"),
      marginTop: ("" + (_vm.setMarginTop())),
      position: ("" + (_vm.setPosition())),
      bottom: ("" + (_vm.setBottom())),
      top: ("" + (_vm.setTop())),
      zIndex: "1",
    }),attrs:{"id":(_vm.name + "_dropdown")}},_vm._l((_vm.dropdownValues),function(val,index){return _c('div',{key:("dd_" + index),on:{"mousedown":function (e) {
          e.preventDefault();
        }}},[_c('div',{staticClass:"search__item block block__align-center",on:{"click":function($event){return _vm.setElement(val)}}},[(_vm.dropdownProps && _vm.dropdownProps.img && val[_vm.dropdownProps.img])?_c('img',{staticClass:"component__margin-right",attrs:{"src":val[_vm.dropdownProps.img]}}):_vm._e(),_c('div',[_vm._v(_vm._s(val.label))]),(_vm.dropdownProps && _vm.dropdownProps.sublabel && val[_vm.dropdownProps.sublabel])?_c('div',{staticClass:"component__margin-left"},[_vm._v(" ("+_vm._s(val[_vm.dropdownProps.sublabel])+") ")]):_vm._e()])])}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }