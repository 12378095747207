<template>
  <div>
    <ModalForm
      :show="authModal"
      :title="setModalTitle()"
      @cancel="closeAuthModal"
      :width="width"
      :closable="closable"
      :keyboard="closable"
      :maskClosable="closable"
      :ok="setModalOk()"
      @setForm="setModalForm($event)"
      :hideButton="true"
    >
      <template v-slot:body>
        <div v-if="modalType === 'sign-in'">
          <div v-if="error424" class="auth__error424">
            {{ auth.error_424[language] }}
          </div>
          <div v-if="error403" class="auth__error424">
            {{ auth.error_403[language] }}
          </div>
          <a-form-item :label="auth.email_label[language]">
            <a-input
              @change="clearL"
              v-decorator="[
                'l',
                {
                  rules: [
                    { required: true, message: auth.empty_error[language] },
                    { validator: check404 },
                    { validator: check401 },
                  ],
                },
              ]"
              placeholder="example@domain.com"
            />
          </a-form-item>
          <a-form-item :label="auth.pass_label[language]">
            <a-input
              @change="clearP"
              v-decorator="[
                'p',
                {
                  rules: [
                    { required: true, message: auth.empty_error[language] },
                    { validator: check404 },
                    { validator: check401 },
                  ],
                },
              ]"
              type="password"
              :placeholder="auth.pass_label[language]"
            />
          </a-form-item>
          <div class="auth__margin">
            <I5Button
              type="primary"
              :text="auth.sign_in[language]"
              fontSize="15px"
              html-type="submit"
              minHeight="32px"
            />
          </div>
          <div class="auth__margin block block__center">
            <span class="auth__text">{{ auth.have_account[language] }}</span>
            <span
              class="auth__sign-up header__modal_pointer component__margin-left"
              @click="setModalType('sign-up')"
            >
              {{ auth.sign_up[language] }}
            </span>
          </div>
          <div
            class="block block__center header__modal_pointer header__modal_sign-up"
            @click="setModalType('recovery')"
          >
            {{ auth.forgot_password[language] }}
          </div>
        </div>
        <div v-if="modalType === 'sign-up'">
          <div>
            <a-form-item :label="auth.company_name[language]" has-feedback>
              <a-input
                v-decorator="[
                  'company_name_when_registering',
                  { rules: [{ required: true, message: auth.empty_error[language] }] },
                ]"
                :placeholder="auth.company_name[language]"
              />
            </a-form-item>
          </div>
          <div :class="windowWidth > t_lg ? 'block' : ''">
            <a-form-item :label="auth.first_name[language]" has-feedback style="width: 100%">
              <a-input
                v-decorator="[
                  'first_name',
                  { rules: [{ required: true, message: auth.empty_error[language] }] },
                ]"
                :placeholder="auth.first_name[language]"
              />
            </a-form-item>
            <a-form-item
              :label="auth.last_name[language]"
              :style="
                windowWidth > t_lg ? 'margin-left: 8px !important; width: 100%;' : 'width: 100%;'
              "
              has-feedback
            >
              <a-input
                v-decorator="[
                  'last_name',
                  {
                    rules: [{ required: true, message: auth.empty_error[language] }],
                  },
                ]"
                :placeholder="auth.last_name[language]"
              />
            </a-form-item>
          </div>
          <div :class="windowWidth > t_lg ? 'block' : ''">
            <a-form-item :label="auth.email_label[language]" has-feedback style="width: 100%">
              <a-input
                @change="clearEmail"
                v-decorator="[
                  'email',
                  {
                    rules: [
                      { required: true, message: auth.empty_error[language] },
                      { type: 'email', message: auth.error_email_format[language] },
                      { validator: check409 },
                    ],
                  },
                ]"
                name="new-email"
                placeholder="example@domain.com"
              />
            </a-form-item>
            <a-form-item
              :label="auth.phone_label[language]"
              has-feedback
              :style="
                windowWidth > t_lg ? 'margin-left: 8px !important; width: 100%;' : 'width: 100%;'
              "
            >
              <a-input
                v-decorator="[
                  'mobile',
                  {
                    rules: [{ required: true, message: auth.empty_error[language] }],
                  },
                ]"
                :placeholder="auth.phone_example[language]"
              />
            </a-form-item>
          </div>
          <div :class="windowWidth > t_lg ? 'block' : ''">
            <a-form-item :label="auth.pass_label[language]" has-feedback style="width: 100%">
              <a-input
                @blur="handleConfirmBlur"
                v-decorator="[
                  'password',
                  {
                    rules: [
                      { required: true, message: auth.empty_error[language] },
                      { validator: validateToNextPassword },
                      { min: 6, message: auth.error_pass_min[language] },
                      { max: 200, message: auth.error_pass_max[language] },
                      {
                        pattern: RegExp(/^(?=.*?[a-zA-Z])(?=.*?[0-9])/),
                        message: auth.error_pass_contain[language],
                      },
                    ],
                  },
                ]"
                type="password"
                :placeholder="auth.pass_label[language]"
              />
            </a-form-item>
            <a-form-item
              :label="auth.pass_confirm[language]"
              :style="
                windowWidth > t_lg ? 'margin-left: 8px !important; width: 100%;' : 'width: 100%;'
              "
              :class="windowWidth > t_lg ? 'component__margin-left' : ''"
              has-feedback
            >
              <a-input
                v-decorator="[
                  'confirm',
                  {
                    rules: [
                      { required: true, message: auth.empty_error[language] },
                      { validator: compareToFirstPassword },
                    ],
                  },
                ]"
                type="password"
                :placeholder="auth.pass_confirm[language]"
              />
            </a-form-item>
          </div>
          <div class="auth__margin">
            <I5Button
              type="primary"
              :text="auth.sign_up_button[language]"
              fontSize="15px"
              html-type="submit"
              minHeight="32px"
            />
          </div>
          <div class="auth__margin block block__center">
            <span class="auth__text">{{ auth.already_have_account[language] }}</span>
            <span
              class="auth__sign-up header__modal_pointer component__margin-left"
              @click="setModalType('sign-in')"
            >
              {{ auth.sign_in[language] }}
            </span>
          </div>
        </div>
        <div v-if="modalType === 'success'">
          <div class="auth__success block block__center auth__margin">
            {{ auth.modal_success_text[language] }}
          </div>
          <div class="block block__center auth__margin">
            {{ auth.success_recovery[language] }}
          </div>
          <div class="auth__margin">
            <I5Button
              type="primary"
              :text="auth.sign_in[language]"
              fontSize="15px"
              @click="setModalType('sign-in')"
              minHeight="32px"
            />
          </div>
        </div>
        <div v-if="modalType === 'recovery'">
          <a-form-item :label="auth.email_label[language]">
            <a-input
              @change="clearRecovery"
              v-decorator="[
                'email_recovery',
                {
                  rules: [
                    { required: true, message: auth.empty_error[language] },
                    { validator: check404Recovery },
                  ],
                },
              ]"
              placeholder="example@domain.com"
            />
          </a-form-item>
          <div class="auth__margin">
            <I5Button
              type="primary"
              :text="auth.modal_get_recovery[language]"
              fontSize="15px"
              html-type="submit"
              minHeight="32px"
            />
          </div>
          <div class="auth__margin block block__center">
            <span
              class="auth__sign-up header__modal_pointer component__margin-left"
              @click="setModalType('sign-in')"
            >
              {{ auth.sign_in[language] }}
            </span>
          </div>
        </div>
        <div v-if="modalType === 'recovery-success'">
          <div class="auth__success block block__center auth__margin">
            {{ auth.success_recovery[language] }}
          </div>
          <div class="auth__margin">
            <I5Button
              type="primary"
              :text="auth.sign_in[language]"
              fontSize="15px"
              @click="setModalType('sign-in')"
              minHeight="32px"
            />
          </div>
        </div>
      </template>
    </ModalForm>
  </div>
</template>

<script>
import { sha256 } from "js-sha256";
import { query, queryWithout401 } from "@/utilities/axios";
import auth from "@/assets/text/auth.json";

const I5Button = () => import("@/components/i5Button/I5Button.vue");
const ModalForm = () => import("@/components/modalForm/ModalForm.vue");

export default {
  name: "AuthModal",
  data() {
    return {
      auth,
      unsubscribe: () => {},
      authModal: false,
      error404: false,
      error404Recovery: false,
      error401: false,
      error403: false,
      error409: false,
      error424: false,
      form: null,
      confirmDirty: false,
      modalType: "sign-in",
      width: 520,
    };
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    show: function (val) {
      this.authModal = val;
    },
  },
  methods: {
    setModalOk() {
      if (this.modalType === "sign-in") {
        return this.authorization;
      } else if (this.modalType === "sign-up") {
        return this.reg;
      } else if (this.modalType === "recovery") {
        return this.recovery;
      } else {
        return () => {};
      }
    },
    setModalForm(form) {
      this.form = form;
    },
    recovery(values, form) {
      return new Promise((resolve, reject) => {
        query(
          "post",
          "passwordRecoveryByMail",
          {
            mail: values.email_recovery.toLowerCase(),
          },
          {},
        )
          .then(() => {
            this.modalType = "recovery-success";
            resolve({
              notClose: true,
            });
          })
          .catch((err) => {
            if (err.response) {
              const status = err.response.status;
              if (status === 404) {
                this.error404Recovery = true;
                form.validateFields(["email_recovery"], { force: true });
              }
            }
            reject();
          });
      });
    },
    reg(values, form) {
      return new Promise((resolve, reject) => {
        query(
          "put",
          "addUser",
          {
            email: values.email.toLowerCase(),
            pass: sha256(values.password),
            company_name_when_registering: values.company_name_when_registering,
            first_name: values.first_name,
            last_name: values.last_name,
            mobile: values.mobile,
          },
          {},
        )
          .then(() => {
            this.modalType = "success";
            resolve({
              notClose: true,
            });
          })
          .catch((err) => {
            if (err.response) {
              const status = err.response.status;
              if (status === 409) {
                this.error409 = true;
                form.validateFields(["email"], { force: true });
              }
            }
            reject();
          });
      });
    },
    authorization(values, form) {
      return new Promise((resolve, reject) => {
        queryWithout401(
          "post",
          "auth",
          {
            l: values.l.toLowerCase(),
            p: sha256(values.p),
            location: "market",
          },
          {},
        )
          .then((item) => {
            localStorage.setItem("token", item.token);
            this.$emit("ok", item.token);
            this.$store.dispatch("user/getUser").then(() => {
              if (this.$store.state.user.afterAuthorize) {
                this.$store.state.user.afterAuthorize();
              }
            });
            resolve({
              notClose: false,
            });
          })
          .catch((err) => {
            if (err.response) {
              const status = err.response.status;
              if (status === 401) {
                this.error401 = true;
                form.validateFields(["p", "l"], { force: true }).then(() => {
                  this.error401 = false;
                });
              } else if (status === 403) {
                this.error403 = true;
                setTimeout(() => {
                  this.error403 = false;
                }, 30000);
              } else if (status === 404) {
                this.error404 = true;
                form.validateFields(["p", "l"], { force: true }).then(() => {
                  this.error404 = false;
                });
              } else if (status === 424) {
                this.error424 = true;
                setTimeout(() => {
                  this.error424 = false;
                }, 30000);
              }
            }
            reject();
          });
      });
    },
    handleConfirmBlur(e) {
      const value = e.target.value;
      this.confirmDirty = this.confirmDirty || !!value;
    },
    showAuthModal() {
      this.authModal = true;
    },
    check409(rule, value, callback) {
      if (!this.error409) {
        callback();
        return;
      }
      callback(this.auth["409_error"][this.language]);
    },
    checkPass(rule, value, callback) {
      if (!this.error401) {
        callback();
        return;
      }
      callback(this.auth.pass_error[this.language]);
    },
    check404(rule, value, callback) {
      if (!this.error404) {
        callback();
        return;
      }
      callback(this.auth["404_error"][this.language]);
    },
    check401(rule, value, callback) {
      if (!this.error401) {
        callback();
        return;
      }
      callback(this.auth["401_error"][this.language]);
    },
    check404Recovery(rule, value, callback) {
      if (!this.error404Recovery) {
        callback();
        return;
      }
      callback(this.auth["404_error"][this.language]);
    },
    validateToNextPassword(rule, value, callback) {
      const form = this.form;
      if (value && this.confirmDirty) {
        form.validateFields(["confirm"], { force: true });
      }
      callback();
    },
    setModalTitle() {
      if (this.modalType === "sign-in") {
        return this.auth.sign_in[this.language];
      } else if (this.modalType === "sign-up") {
        return this.auth.sign_up_title[this.language];
      } else if (this.modalType === "success") {
        return this.auth.modal_success[this.language];
      } else if (this.modalType === "recovery") {
        return this.auth.modal_recovery_title[this.language];
      } else if (this.modalType === "recovery-success") {
        return this.auth.modal_recovery_title[this.language];
      } else {
        return "";
      }
    },
    compareToFirstPassword(rule, value, callback) {
      const form = this.form;
      if (value && value !== form.getFieldValue("password")) {
        callback(this.auth.error_pass_match[this.language]);
      } else {
        callback();
      }
    },
    setModalType(type) {
      if (type === "sign-up") {
        this.width = 1000;
      } else {
        this.width = 520;
      }
      this.modalType = type;
    },
    clearL() {
      if (this.error401) {
        this.error401 = false;
        this.form.validateFields(["p", "l"], { force: true });
      }
      if (this.error404) {
        this.error404 = false;
        this.form.validateFields(["p", "l"], { force: true });
      }
      if (this.error403) {
        this.error403 = false;
      }
      if (this.error424) {
        this.error424 = false;
      }
    },
    clearP() {
      if (this.error401) {
        this.error401 = false;
        this.form.validateFields(["p", "l"], { force: true });
      }
    },
    clearEmail() {
      this.error409 = false;
      this.form.validateFields(["email"], { force: true });
    },
    clearRecovery() {
      this.error404Recovery = false;
      this.form.validateFields(["email_recovery"], { force: true });
    },
    closeAuthModal() {
      this.authModal = false;
      this.modalType = "sign-in";
      this.$emit("close");
    },
  },
  computed: {
    language() {
      return this.$store.state.index.language;
    },
    closable() {
      return this.$store.state.user.closableModal;
    },
    windowWidth() {
      return this.$store.state.index.width;
    },
    t_lg() {
      return this.$store.state.index.t_lg;
    },
  },
  mounted() {
    this.unsubscribe = this.$store.subscribe((mutation) => {
      if (mutation.type === "user/setShowAuthorizeModal") {
        const item = mutation.payload;
        if (item) {
          this.showAuthModal();
        }
      }
    });
  },
  beforeDestroy() {
    this.unsubscribe();
    this.width = 520;
    this.modalType = "sign-in";
  },
  components: {
    I5Button,
    ModalForm,
  },
};
</script>
