<template>
  <I5Modal :show="show" :title="text.edit_modal_name[language]" @close="onHandlerClose">
    <template slot="body">
      <div
        style="padding: 20px; overflow-y: scroll; height: calc(100% - 80px)"
        class="block block__column"
      >
        <div class="block" style="gap: 10px">
          <Input
            label="Наименование на русском"
            name="name_rus"
            @change="(e) => onHandleChange(e, 'name_rus')"
            style="width: 100%"
          />
        </div>
        <div class="block" style="gap: 10px">
          <Input
            label="Наименование (Eng)"
            name="name_eng"
            @change="(e) => onHandleChange(e, 'name_eng')"
            style="width: 100%"
          />
          <Input
            label="Наименование (De)"
            name="name_de"
            @change="(e) => onHandleChange(e, 'name_de')"
            style="width: 100%"
          />
        </div>
        <div class="block" style="gap: 10px">
          <Input
            label="Наименование (Tr)"
            name="name_tr"
            @change="(e) => onHandleChange(e, 'name_tr')"
            style="width: 100%"
          />
          <Input
            label="Наименование (Zh)"
            name="name_zh"
            @change="(e) => onHandleChange(e, 'name_zh')"
            style="width: 100%"
          />
        </div>
        <div class="block" style="gap: 10px">
          <Textarea
            label="Описание на русском"
            name="description_rus"
            @change="(e) => onHandleChange(e, 'description_rus')"
            style="width: 100%"
          />
        </div>
        <div class="block" style="gap: 10px">
          <Textarea
            label="Описание (Eng)"
            name="description_eng"
            @change="(e) => onHandleChange(e, 'description_eng')"
            style="width: 100%"
          />
          <Textarea
            label="Описание (De)"
            name="description_de"
            @change="(e) => onHandleChange(e, 'description_de')"
            style="width: 100%"
          />
        </div>
        <div class="block" style="gap: 10px">
          <Textarea
            label="Описание (Tr)"
            name="description_tr"
            @change="(e) => onHandleChange(e, 'description_tr')"
            style="width: 100%"
          />
          <Textarea
            label="Описание (Zh)"
            name="description_zh"
            @change="(e) => onHandleChange(e, 'description_zh')"
            style="width: 100%"
          />
        </div>
        <h3>Параметры</h3>
        <Search
          @change="addParam($event)"
          :query="{
            method: 'post',
            url: 'getMachineToolTypeParameters',
            findName: 'find',
            label: 'name',
            value: 'id',
          }"
        />
        <div style="width: 100%" v-if="params && params.length > 0">
          <div
            v-for="(el, index) in params"
            v-bind:key="`${index}_param`"
            class="block block__between block__align-center"
            style="margin-bottom: 10px; border: 2px solid #f1f1f1; border-radius: 8px; padding: 5px"
          >
            <b>{{ el.name }}</b>
            <div class="tech-req__block_delete" @click="deleteParam(index)">
              <TrashIcon />
            </div>
          </div>
        </div>
      </div>
      <div
        style="height: 80px; gap: 12px; padding: 0 20px"
        class="block block__align-center block__right"
      >
        <Button type="default" @click="$emit('close')" text="Отмена" />
        <Button type="primary" text="Сохранить" @click="saveEdit" />
      </div>
    </template>
  </I5Modal>
</template>

<script>
import I5Modal from "@/components/i5Modal/I5Modal.vue";
import text from "@/assets/text/tools_nomenclature.json";
import Input from "@/components/input/Input.vue";
import Textarea from "@/components/textarea/Textarea.vue";
import TrashIcon from "@/assets/images/tech/trash.svg";
import Button from "@/components/button/Button.vue";
import Search from "@/components/search/Search.vue";
import { query } from "@/utilities/axios";

export default {
  name: "EditToolNomenclature",
  data() {
    return {
      text,
      editData: {
        name_rus: "",
        name_eng: "",
        name_de: "",
        name_tr: "",
        name_zh: "",
        description_rus: "",
        description_rus_eng: "",
        description_rus_de: "",
        description_rus_tr: "",
        description_rus_zh: "",
      },
      params: [],
    };
  },
  components: {
    I5Modal,
    Input,
    TrashIcon,
    Button,
    Search,
    Textarea,
  },
  computed: {
    language() {
      return this.$store.state.index.language;
    },
  },
  props: {
    show: {
      type: Boolean,
    },
    parameters: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    parameters: {
      immediate: true,
      handler: function (val) {
        this.params = val;
      },
    },
  },
  methods: {
    onHandlerClose() {
      this.$emit("close");
    },
    onHandleChange(e, name) {
      this.editData[name] = e;
    },
    deleteParam(index) {
      const params = [...this.params];
      params.splice(index, 1);
      this.params = params;
    },
    addParam(value) {
      this.params.unshift(value);
    },
    saveEdit() {
      const editData = {
        ...this.editData,
        id_machine_tool_parameters: this.params.map((el) => el.id),
      };
      query("post", "/updateMachineToolTypeInfo", editData, {
        token: localStorage.getItem("token"),
      }).then(() => {
        this.$emit("update");
        this.$emit("close");
      });
    },
  },
};
</script>
